import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text, Span } from "../../../components/Core";
import { device } from "../../../utils";





const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;










const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const BAcxxnffeCausesPage = () => (
  <>
    {/* <!-- Content section 1 --> */}
  
    <Section bg="#ffffff" py={4}>
        <Container className="pt-3 pb-3">
          <Row className="pb-4 align-items-center">
            <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
              <div
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
          <Iwrap>

          
          <Itext as="h2">Ingorwn Hairs <span>London</span>
</Itext> 
          </Iwrap>
                <SecondText>
                Symptoms and Causes of Ingrown Hairs
                </SecondText>

               
           
              </div>
            </div>
          
          </Col>

         
        </Row>

        <Row className="align-items-center">
            <Col lg="12">
            <Text>
            Hair removal treatments that irritate or harm the skin, such as shaving, waxing, or plucking, can result in ingrown hairs. Ingrown hairs are more common in those with curly or coarse hair because the hair can readily curl back into the skin.<br /> <br /> Tight clothing, sweat, and friction can aggravate the skin and cause ingrown hairs. Shaving, waxing build up of dead skin on surface of skin, hair cannot break through onto surface of the skin, gets trapped underneath skin and causes infection of follicles resulting in pustules and ingrown hairs 


<br /> <br />
               </Text>
            </Col>

        </Row>

        <Row className="align-items-center">
            <Col lg="12">
              <Text>
              Redness, irritation, itching, and pain are all symptoms of ingrown hairs. They may also form small, raised, red or white pimples or pimple-like lesions that are pus-filled. Ingrown hairs can cause infection, scarring, or discolouration in certain people.

              <br /> <br />
To avoid ingrown hairs, avoid shaving too closely, use shaving cream or gel, exfoliate the skin on a regular basis, and wear loose-fitting clothing. Laser hair removal can completely destroy the hair follicle. For relief from ingrown hairs, an extraction treatment can help to remove the build-up of dead skin  and hair. 
</Text>
            </Col>
        </Row>
       
   
      </Container>

    
     
    </Section>
   
    
  </>
);

export default BAcxxnffeCausesPage;
